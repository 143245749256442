import UpsideLeftCorner from '../Corners/UpsideCorner'
import JoinCards from './JoinCards'
import './JoinUs.css'
import { useTranslation } from 'react-i18next';

const JoinUs = () => {
    const { t } = useTranslation()
    return (
        <section className="JoinUs-section" id="JoinUs">
            
            
            <UpsideLeftCorner link="/image/corner_1.png"/>
            <h2>{t("join-title")}</h2>
           
            <div className='join-content'>
                <JoinCards
                text={t("join-card-1")}
                imageUrl={'/image/sou_pessoa.png'}
                link="/VoluntaryPerson"/>
                <JoinCards
                text={t("join-card-2")}
                imageUrl={'/image/sou_empresa.png'}
                link="/Company"/>
                
            </div>
        </section>
    )
}

export default JoinUs
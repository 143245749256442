import './TeamCards.css'
import  { useState } from 'react';



export const TeamCards = ({ name, photo, role, personDescription, linkedinLink }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const altImage = [name + '-' + role]

    return (
        <div className="team-cards">
            <div className='team-first-content'>
                <img src={photo} alt={altImage} ></img>
                <button className='team-more-info' onClick={openModal}>
                    +
                </button>
            {modalOpen && (
                <div className="modal-overlay">
                        <button className="close-button modal-btn" onClick={closeModal}>X</button>
                    <div className="modal">
                        <h2>{name}</h2>
                        <span>{role}</span>
                        <img src={photo} alt={altImage} />
                        <div className='person-description'>

                        <p>{personDescription}</p>
                        </div>
                    </div>
                        <div className='linkedin-btn'>

                        <a href={linkedinLink}>
                            <button className='btn-voluntary btn-team'>
                                    <span><img src='/image/linkedin_black.png' alt="Linkedin"></img></span> Linkedin
                            </button>
                        </a>
                        </div>
                </div>
            )}
            </div>
            <div className='team-name'>
                <h1>
                    {name}
                </h1>
                <span>{role}</span>
            </div>

        </div>
    )


}
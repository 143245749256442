import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import enJson from './languages/en.json'
import ptBRjson from './languages/pt-br.json'
import esjson from './languages/es.json'

i18next.use(initReactI18next).init({
    fallbackLng: "ptBR", 
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: enJson,
        ptBR: ptBRjson,
        es: esjson
    }

})

export default i18next
import DownsideLeftCorner from '../Corners/UpdownLeftCorner'
import UpsideLeftCorner from '../Corners/UpsideCorner'
import './About.css'
import CardAbout from './components/Card-about'
import { useTranslation } from 'react-i18next';

const AboutSection = () => {
    const { t } = useTranslation()
    return (
        <section id='Sobre'className='about'>
            <UpsideLeftCorner link='/image/corner_1.png'/>
            <div className='about-full-content'>
                <div className='about-first-content'>

                    <CardAbout />
                    <div className='map'>
                        <h2><strong>{t("aboutlegend")}</strong></h2>
                        <img src={t("aboutimagemap")} alt="Mapa com dados da diversidade do instituto Joga Junto"></img>
                        <p><span>{t("abouttext-2")}</span></p>
                    </div>
                </div>
                <div className='about-second-content'>
                    <img src={t("aboutods1")} alt="ODS 1 Erradicação da pobreza"></img>
                    <img src={t("aboutods2")} alt="ODS 4 Educação de qualidade"></img>
                    <img src={t("aboutods3")} alt="ODS 5 Igualdade de Gênero"></img>
                    <img src={t("aboutods4")} alt="ODS 8 Trabalho digno e crescimento economico"></img>
                    <img src={t("aboutods5")} alt="ODS 10 Redução das desigualdades"></img>
                </div>
                
            </div>
            <DownsideLeftCorner link='/image/corner_2.png'/>
        </section>
    )
}

export default AboutSection
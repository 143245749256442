import './Banner.css'
import VideoComponent from './Video'



const Banner = () => {
    // const phrases = [" Transforme uma comunidade ",  " Promova a diversidade ", " Impacte a vida de pessoas ", " Faça do seu negócio, um negócio social "];
    // const phrases = [" ade ",  " Pade ", " oas "];
    return (
        <section className="main-banner">
            <div>
                <VideoComponent />
            </div>
            
            
            
        </section>
    )
}

export default Banner
import React, { useState } from 'react';
import './Dropdown.css'; // Importe o arquivo CSS para estilização do dropdown
import { useTranslation } from 'react-i18next';
import SubDropdown from './subDropdown';

const Dropdown = (props) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  // console.log(props.items)
  const dropitems = [
    { label: 'Equipe', link: '/team' },
  ];
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="dropdown" onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <button className="dropdown-toggle" >
        {props.name}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {props.items.map(item =><a href={item.link} > <li>
              
                {item.label}
              
              
            </li></a>)}
            <li>
            <SubDropdown name={t("report")} items={dropitems} />
          </li>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

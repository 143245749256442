import UpsideLeftCorner from '../Corners/UpsideCorner'
import Card from '../Metodology/components/Cards'
import './CompanyMethods.css'
import { useTranslation } from 'react-i18next';

export const CompanyMethods = () => {

     const { t } = useTranslation()
     return (
          <section className="company-metods-main voluntary-modalities">
               <UpsideLeftCorner link='/image/corner_1.png' />


               <h2> {t("company_ways_h2")}</h2>
               <div className='company-cards'>
                    <div className='company-cards-first'>

                         <Card
                              frontContent={t("company_card1_title")}
                              backContent={t("company_card1_backcontent")}
                              border="#4863F7"
                              imageFront='/image/doacao_1.png'
                              imageDescription="Coração Azul - representando a doação financeira"
                              backTitle={t("company_card1_title")}
                              imageBack="/image/doacao_back.png"
                              fontColor="black"
                              titleFontSize="1.5rem"
                              backTitleFontSize="2.5rem"
                              backTextAlign='initial'

                         />
                         <Card
                              frontContent={t("company_card2_title")}
                              backContent= {t("company_card2_backcontent")}
                              border="#FCD700"
                              imageFront='/image/patrocinio.png'
                              imageDescription="Cifrão com fundo amarelo - representando patrocinio de turmas"
                              backTitle={t("company_card2_title")}
                              imageBack="/image/patrocinio_back.png"
                              fontColor="black"
                              titleFontSize="1.5rem"
                              backTitleFontSize="2rem"
                              backTextAlign='initial'
                         />
                         <Card
                              frontContent={t("company_card3_title")}
                              backContent={t("company_card3_backcontent")}
                              border="#FD3F3F"
                              imageFront='/image/campanhas.png'
                              imageDescription="Mega fone vermelho - representando campanhas exclusivas"
                              backTitle={t("company_card3_backtitle")}
                              imageBack="/image/campanha_back.png"
                              fontColor="black"
                              titleFontSize="1.5rem"
                              backTitleFontSize="2rem"
                              backTextAlign='initial'
                         />
                    </div>
                    <div className='company-cards-second'>
                         <Card
                              frontContent={t("company_card4_title")}
                              backContent={t("company_card4_backcontent")}
                              border="#04D361"
                              imageFront='/image/contratacao.png'
                              imageDescription="Professor ensinando - representando contratação para treinamentos"
                              backTitle={t("company_card4_title")}
                              imageBack="/image/contratacao_back.png"
                              fontColor="black"
                              titleFontSize="1.5rem"
                              backTitleFontSize="1.5rem"
                              backTextAlign='initial'
                         />
                         <Card
                              frontContent={t("company_card5_title")}
                              backContent={t("company_card5_backcontent")}
                              border="#EC7A1C"
                              imageFront='/image/ofertas.png'
                              imageDescription="Foguete - representando oportunidade aos alunos"
                              backTitle={t("company_card5_backtitle")}
                              imageBack="/image/oportunidade_back.png"
                              fontColor="black"
                              backTitleFontSize="2rem"
                              backTextAlign='initial'

                         />
                    </div>

               </div>
          </section>
     )
}
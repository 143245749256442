import React, { useState } from "react";
import "./styles.css";


const SubDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className="dropdown-toggle">{props.name}</button>
      {isOpen && (
        <ul className="dropdown-menu subDropdown">
          <li>
            <a
              href="https://estaticos-ijj.s3.sa-east-1.amazonaws.com/2023+05+23+-+RELAT%C3%93RIO+DE+GEST%C3%83O+-+Referente+a+2021.pdf"
              target="_blank"
              className="link" rel="noreferrer"
            >
              2021
            </a>
          </li>
          <li>
            <a
              href="https://estaticos-ijj.s3.sa-east-1.amazonaws.com/2023+05+23+-+RELAT%C3%93RIO+DE+GEST%C3%83O+-+Referente+a+2022.pdf"
              target="_blank"
              className="link" rel="noreferrer"
            >
              2022
            </a>
          </li>
          <li>
            <a
              href="https://estaticos-ijj.s3.sa-east-1.amazonaws.com/2024+04+12+-+RELAT%C3%93RIO+DE+GEST%C3%83O+-+Referente+a+2023.pdf"
              target="_blank"
              className="link" rel="noreferrer"
            >
              2023
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SubDropdown;

import './Card.css'
import { useTranslation } from 'react-i18next';


const CardAbout = () => {
    const { t } = useTranslation()
    return (
        <div className='aboutcontent'>
                <img src="/image/Logo_about.png" alt="Logo do instituto Joga Junto"></img>
                <p>{t("abouttext-1")}</p>
                
                <p><span>{t("abouttext-2")}</span></p>
            </div>
    )
}

export default CardAbout
import Card from '../Metodology/components/Cards'
import './PersonDonation.css';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const PersonDonation = () => {
    const { t } = useTranslation()

    let [searchParams, setSearchParams] = useSearchParams();

    const handleContact = () => {
        const params = 'donate=true'; // Valor do parâmetro que você deseja passar
        setSearchParams(params)
  };
    


    return (
        <section className="person-donation-main">
            <div className='person-text-yellow'>
                <h3>
                {t("person-donation-h3")}
                </h3>
            </div>
            <div className='person-cards-content'>

            <div className='person-cards '>
                


                        <Card
                            frontContent={t("donation-card1-title")}
                            backContent={t("donation-card1-backcontent")}
                            border="#4863F7"
                            imageFront='/image/doacao_1.png'
                            imageDescription={t("donation-card1-imagedesc")}
                            backTitle={t("donation-card1-backtitle")}
                            imageBack="/image/doacao_back.png"
                            backTitleFontSize='2rem'
                            backFontSize='2rem'

                            />
                    
                        <Card
                            frontContent={t("donation-card2-title")}
                            backContent={t("donation-card1-backcontent")}
                            border="#FD3F3F"
                            imageFront='/image/doacao_2.png'
                            imageDescription={t("donation-card2-imagedesc")}
                            backTitle={t("donation-card2-backtitle")}
                            imageBack="/image/doacao_back.png"
                            backFontSize='2rem'
                            backTitleFontSize='2rem'
                            />
                   
                    
                        <Card
                            frontContent={t("donation-card3-title")}
                            backContent={t("donation-card3-backcontent")}
                            border="#04D361"
                            imageFront='/image/doacao_3.png'
                            imageDescription={t("donation-card3-imagedesc")}
                            backTitle={t("donation-card3-backtitle")}
                            imageBack="/image/doacao_back.png"
                            backFontSize='2rem'
                            backTitleFontSize='2rem'
                            
                            />
                 
            </div>
                <div className='voluntary-container' >
                    
                    <a href='#Contato'>

                    <button id='btn-donate'className='btn-voluntary' onClick={handleContact}>
                       {t("person-voluntary-btn-2")}
                    </button>
                    </a>
                    
                </div>
                            </div>
        </section>
    )
}
import './Partner.css'
import { useTranslation } from 'react-i18next';


const Partner = () => {
    const { t } = useTranslation()
    const partners =[ 
        { urlImage: '/image/clash.png', alt: 'clash'},
        { urlImage: '/image/mutant.png', alt: 'mutant'},
        { urlImage: '/image/myra.png', alt: 'myra'},
        { urlImage: '/image/intervalor.png', alt: 'intervalor'},
        { urlImage: '/image/episodia.png', alt: 'episodia'},
        { urlImage: '/image/manas.png', alt: 'manas'},
        { urlImage: '/image/carrefour.png', alt: 'carrefour'},
        { urlImage: '/image/pac.png', alt: 'pac'},
        { urlImage: '/image/cufa.png', alt: 'cufa'},
        { urlImage: '/image/caritas.png', alt: 'caritas'},


]
    return (
        <section className='partner-section'>
            <h2>{t("partner-title")}</h2>
            <p>{t("partner-subtitle")}</p>
            <div className='partner-logo'>
                <ul>
                {partners.map(item => (
                <li key={item}>
                       <img src={item.urlImage} alt={item.alt}></img>

                    </li>))}
                </ul>
            </div>
            <div className='partner-joinus'>
                <h2>
                {t("partner-h2")}
                </h2>
            </div>
        </section>
    )
}

export default Partner
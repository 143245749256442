import './CompanyEquivalents.css'

export const EsgCards = ({image, imageDescription, cardText, cardParagraph}) => {
    return (
        <div className="company-esg-container">
            <img src={image} alt={imageDescription}></img>
            <div className="company-esg-text-container">

            <div className="company-esg-card">
                <p>{cardText}</p>
                <span>{cardParagraph}</span>
            </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';

const Typewriter = ({ phrases }) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    const type = () => {
      try {
        const currentPhrase = phrases[currentPhraseIndex];

        // if (!currentPhrase) {
        //   return;
        // }

        if (displayedText.length === currentPhrase.length) {
          setTimeout(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
            setDisplayedText('');
          }, 1000);
        } else {
          if (displayedText.length < currentPhrase.length) {
            const nextChar = currentPhrase[displayedText.length];
            setDisplayedText((prevText) => prevText + nextChar);
          } else {
            // Aqui você pode adicionar alguma lógica caso deseje
            // quando a frase já estiver completamente digitada
          }
        }
      } catch (error) {
        console.error('Ocorreu um erro:', error);
        // Aqui você pode adicionar ações adicionais, como exibir uma mensagem de erro ao usuário
      }
    };

    const typingInterval = setInterval(type, 150); // Ajuste a velocidade de digitação conforme necessário

    return () => {
      clearInterval(typingInterval);
    };
  }, [currentPhraseIndex, displayedText, phrases]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor);
    }, 500); // Tempo de piscar o cursor (ajuste conforme necessário)

    return () => {
      clearInterval(cursorInterval);
    };
  }, []);
  if (displayedText !== undefined) {
    return (
      <div>
        {displayedText}
        {showCursor && '|'}
      </div>
    );

  }
};

export default Typewriter;

import './PersonCards.css'

export const PersonCards = ({imageBack, backgroundColor, imageDescription, title, content}) => {
    const cardContent = Array.isArray(content) ? content : [content];

    
    return (
        <div className="person-card" style={{backgroundColor:backgroundColor}}>
            <img src={imageBack} alt={imageDescription}></img>
            <h4>
                {title}
            </h4>
            <ul>

            {cardContent.map((item, index) => (
                <li key={index}>{item}</li>
                ))}
                </ul>
        </div>
    )
}
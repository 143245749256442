import Footer from "../components/Footer"
import { Team } from "../components/Team"



export const PageTeam = () => {
    
    
    return (

        <section>
               
           <Team/>
            
            <Footer/>
        </section>


    )
}
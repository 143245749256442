import './CompanyEquivalents.css'
import { EsgCards } from './EsgCards'
import { useTranslation } from 'react-i18next';

export const CompanyEquivalents = () => {
    
    const { t } = useTranslation()
    return (
        <section className='company-equivalents'>
            <div className='person-text-yellow company-text'>
                <h3>
                    {t("equivalents-h3")}
                </h3>
            </div>
            <div className='company-cards company-equivalents-cards'>
                    <div className='company-cards-first'>
                        <EsgCards
                        image={t("company_seal_sponsor")}
                        imageDescription = "Selo ESG de patrocinador do Instituto Joga Junto"
                        cardText={t("equivalents-card-1")}/>
                        <EsgCards
                        image={t("company_seal_sponsor")}
                        imageDescription = "Selo ESG de patrocinador do Instituto Joga Junto"
                        cardText={t("equivalents-card-2")}/>
                        <EsgCards
                        image={t("company_seal_sponsor")}
                        imageDescription = "Selo ESG de patrocinador do Instituto Joga Junto"
                        cardText={t("equivalents-card-3")}
                        cardParagraph={t("equivalents-card-3-subtitle")}/>
                    </div>
                    <div className='company-cards-second'>
                        <EsgCards
                        image={t("company_seal_imersao")}
                        imageDescription = "Selo ESG de patrocinador do Instituto Joga Junto"
                        cardText={t("equivalents-card-4")}/>
                        <EsgCards
                        image={t("company_seal_imersao")}
                        imageDescription = "Selo ESG de patrocinador do Instituto Joga Junto"
                        cardText={t("equivalents-card-5")}/>

                    </div>
                    <a href='#Contato'>

                    <button className='btn-voluntary btn-company'>
                    {t("btn-company-text")}
                    </button>
                    </a>
                    </div>
        </section>
    )
}
import './JoinCards.css'


const JoinCards = ({text, imageUrl,link}) => {
    return (
        <a href={link} className="card-join" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="card-content">
                <p className="card-text">{text}</p>
            </div>
        </a>
    )
}

export default JoinCards
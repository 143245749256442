import './Footer.css'
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Footer = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation()

    const socialMedia = [
        {imageUrl: 'image/instagram_footer.png', name: 'Instagram', alt: 'Instagram do Instituto Joga Junto', url: 'https://www.instagram.com/institutojogajunto/'},
        {imageUrl: 'image/linkedin_footer.png', name: 'Linkedin', alt: 'Instagram do Instituto Joga Junto', url: 'https://www.linkedin.com/company/institutojogajunto/'},
        {imageUrl: 'image/facebook_footer.png', name: 'Facebook', alt: 'Instagram do Instituto Joga Junto', url: 'https://www.facebook.com/p/Instituto-Joga-Junto-100071655120252/'},
    ]
    const handleContact = () => {
        const params = 'donate=true'; 
        setSearchParams(params)
  };

    return (
        <footer>
            <div className='footer-column'>
            <div>
            <a href="/"><img src="/image/logo_jogajunto_white.png" alt="Logo do instituto Joga Junto referênciando ao site"></img></a>
            <a href="https://www.paypal.com/donate/?hosted_button_id=ABNWG273YLYWU">
                <button id='btn-donate' className='btn-voluntary btn-footer' onClick={handleContact}>
                {t("donate")}
                </button>
                </a>
            </div>
            
            <div>
                <ul>
                    <li>
                        <a href='#home'>
                                <h4>Organização</h4>
                            </a>
                    
                    </li>
                    <li>
                        <a href='#Sobre'>{t("about")}</a>
                    </li>
                    <li>
                        <a href="#metodologia">{t("method")}</a>
                    </li>
                    <li>
                        <a href="#Projetos">{t("projects")}</a>
                    </li>
                    <li>
                        <a href="#Contato">{t("contact")}</a>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        <a href='#Projects'>
                            <h4>{t("projects")}</h4>
                            </a>
                    
                    </li>
                    <li>
                        <a href='#Projects'>TRIP</a>
                    </li>
                    <li>
                        <a href='#Projects' >FARM</a>
                    </li>
                    <li>
                        <a href='#Projects' >VOA</a>
                    </li>
                    <li>
                        <a href='#Projects' >GOAL</a>
                    </li>
                </ul>
            </div>
            <div>
                
            </div>
            </div>
            <div className='social-media-footer'>
                <ul>
                {socialMedia.map(item => (
              <li key={item.name}>
                <a href={item.url}><img src={item.imageUrl} alt={item.alt}></img></a>
              </li>))}
                </ul>
            </div>
            <p>{t("footer-copyright")}</p>
        </footer>

    )
}

export default Footer
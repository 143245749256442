import DownsideLeftCorner from '../Corners/UpdownLeftCorner'
import './Team.css'
import { TeamCards } from './TeamCards'
import { useTranslation } from 'react-i18next';


export const Team = () => {
    const { t } = useTranslation()

    return (
        <article className="team-main" >
            <section className="team-main-section">

            <div className='team-cards-container'>
                <div className='team-cards-content'>

                <TeamCards
                name="Andrea Lua"
                photo="/image/andrea_lua_disarno.png"
                role="CEO"
                linkedinLink="https://www.linkedin.com/in/andrealua/"
                personDescription={t("lua_desc")}/>
                <TeamCards
                name="Vivien Zilberman"
                photo="/image/vivien_zilberman.png"
                role={t("vivien_role")}
                linkedinLink="https://www.linkedin.com/in/vivienzilberman/"
                personDescription={t("vivien_desc")}/>
                <TeamCards
                name="Ivan Silvati"
                photo="/image/ivan_silvati.png"
                role={t("ivan_role")}
                linkedinLink="https://www.linkedin.com/in/ivansilvati/"
                personDescription={t("ivan_desc")}/>
                <TeamCards
                name="Matheus Geambastiane"
                photo="/image/matheus_geambastiane.png"
                role={t("matheus_role")}
                linkedinLink="https://www.linkedin.com/in/matheus-geambastiane-/"
                personDescription={t("matheus_desc")}/>
                <TeamCards
                name="Viviane Araújo"
                photo="/image/viviane_araujo.png"
                role={t("viviane_role")}
                linkedinLink="https://www.linkedin.com/in/viviane-araujo-748047191/"
                personDescription={t("viviane_desc")}/>
                <TeamCards
                name="Jessica Nunes"
                photo="/image/jessica_nunes.png"
                role={t("jessica_role")}
                linkedinLink="https://www.linkedin.com/in/j%C3%A9ssica-nunes-324b35195/"
                personDescription={t("jessica_desc")}/>
                <TeamCards
                name="Vanessa Silva"
                photo="/image/vanessa_silva.png"
                role={t("vanessa_role")}
                linkedinLink="https://www.linkedin.com/in/vanessa-silva-ferreira/"
                personDescription={t("vanessa_desc")}/>
                <TeamCards
                name="Maria Luiza Aleixo"
                photo="/image/maria_luiza_aleixo.png"
                role="Designer"
                linkedinLink="https://www.linkedin.com/in/luizaaleixo/"
                personDescription={t("luiza_desc")}/>
                <TeamCards
                name="Michelle Barreto"
                photo="/image/michele_barreto.png"
                role={t("michelle_role")}
                linkedinLink="https://www.linkedin.com/in/michelle-pb/"
                personDescription={t("michelle_desc")}/>
                <TeamCards
                name="Vini Vieira"
                photo="/image/vini_vieira.png"
                role={t("vini_role")}
                personDescription={t("vini_desc")}
                linkedinLink="https://www.linkedin.com/in/vin%C3%ADcius-manoel-c-vieira-0906311ab/"
                
                />

                </div>
            </div>
            <DownsideLeftCorner link="/image/corner_2.png"/>
            </section>
        </article>
    )
}
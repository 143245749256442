import ContactForm from "../components/Contact"
import Footer from "../components/Footer"
import { PersonBanner } from "../components/PersonBanner"
import { PersonDonation } from "../components/PersonDonation"
import { PersonVoluntary } from "../components/PersonVoluntary"
import { useTranslation } from 'react-i18next';

export const PagePerson = () => {
    const { t } = useTranslation()
    const assunto = [
        
         
        
        
        
        t("contactsub-2"),
        t("contactsub-12"),
        
        t("contactsub-4"),
        t("contactsub-5"),
        t("contactsub-6"),
        t("contactsub-7"),
        t("contactsub-11"),
        ]


    return (
        <div>
            
            <PersonBanner/>
            <PersonVoluntary/>
            <PersonDonation/>
            <ContactForm opcoesAssunto={assunto} title={t("person-contact-title")}/>
            <Footer/>
        </div>
    )
}
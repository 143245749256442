import './ProjectCard.css'



const ProjectCard = ({ProjectImage, ProjectDesc}) => {
    return (
        <div className='project-card'>
            <div>

            <img src={ProjectImage} alt={ProjectDesc}></img>
            </div>
                <p>
                
                {ProjectDesc}
                </p>
            
        </div>
    )
}

export default ProjectCard
import "./CompanyBanner.css"
import { useTranslation } from 'react-i18next';



export const CompanyBanner = () => {
    const { t } = useTranslation()

    return (
        <main className='banner-company'>
            <div>

            <h1>
            {t("company_banner-h1")}
            </h1>
            <p>
            
            {t("company_banner-p1")}
            </p>
            <p>
            {t("company_banner-p2")}
            
            </p>
            </div>
        </main>
    )
}
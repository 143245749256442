import './DownsideLeftCorner.css'

const DownsideLeftCorner = (props) => {
    return (
        <div className='downside-corner'>
            <img src={props.link} alt="efeito visual preto no canto superior da seção Sobre"></img>

        </div>
    )
}

export default DownsideLeftCorner
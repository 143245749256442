import DownsideLeftCorner from '../Corners/UpdownLeftCorner'
import UpsideLeftCorner from '../Corners/UpsideCorner'
import ProjectCard from './ProjectCard'
import './Projects.css'
import { useTranslation } from 'react-i18next';


const Project = () => {
    const { t } = useTranslation()
    return (
        <section id='Projetos' className='project-container'>
            <div className='project-content'>

            <UpsideLeftCorner link="/image/corner_1.png"/>
            <h2>{t("project-title")}</h2>
            <ul>
                <li>
            <ProjectCard 
            ProjectImage="/image/trip_logo.png"
            ProjectDesc={t("trip-desc")}/>
            <ProjectCard 
            ProjectImage="/image/farm_logo.png"
            ProjectDesc={t("farm-desc")}/>
            <ProjectCard 
            ProjectImage="/image/voa_logo.png"
            ProjectDesc={t("voa-desc")}/>
            <ProjectCard 
            ProjectImage="/image/goal_logo.png"
            ProjectDesc={t("goal-desc")}/>
            <ProjectCard 
            ProjectImage={t("your_project_img")}
            ProjectDesc={t("your-desc")}/>

                </li>
            </ul>

            <DownsideLeftCorner link="/image/corner_2.png"/>
            </div>
        </section>  
    )

}


export default Project 
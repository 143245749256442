import './PersonBanner.css'
import { useTranslation } from 'react-i18next';


export const PersonBanner = () => {
    const { t } = useTranslation()
    return(
        <main className='banner-person'>
            <h1>
            {t("person-banner-h1")}
            </h1>
        </main>
    )
}
import './ESG.css'
import { useTranslation } from 'react-i18next';


const EsgSection = () => {
    const { t } = useTranslation()

    return (
        <section className='esg-section'>
            <div className='esg-text'>
                <h3>
                {t("esg-title")}
                </h3>
                <p>
                 
                {t("esg-text-1")}
                </p>
                <p>
                {t("esg-text-2")}
                
                </p>

                <p>
                {t("esg-text-3")}
                
                </p>
            </div>
            <div className='esg-stamp'>
                <div className='esg-container'>
                    <div>
                        <img src={t("esg-seal-1")} alt='Selo ESG para quem apoia a imersão'></img>
                        <img src={t("esg-seal-2")} alt='Selo ESG para quem apoia o negócio'></img>
                        <img src={t("esg-seal-3")} alt='Selo ESG para quem patrocina'></img>
                        </div>
                </div>

            </div>
            <div></div>
        </section>
    )

}

export default EsgSection
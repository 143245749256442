import { t } from "i18next"
import { CompanyBanner } from "../components/CompanyBanner"
import { CompanyEquivalents } from "../components/CompanyEquivalents"
import { CompanyMethods } from "../components/CompanyMethods"
import ContactForm from "../components/Contact"
import Footer from "../components/Footer"
import { useTranslation } from 'react-i18next';



export const PageCompany = () => {
    const { t } = useTranslation()
    const opcoesAssunto = [
        
        
        
        
        
        
        
        t("contactsub-2"), // Doação
        t("contactsub-12"),
        t("contactsub-3"), //Contratar profissional
        t("contactsub-4"), //Contratar projetos para minha empresa
        
        t("contactsub-8"),
        t("contactsub-9"),
        t("contactsub-10"),
        t("contactsub-13"),
        t("contactsub-14"),
        
        
        
       ]
    return (
        <div>
            <CompanyBanner/>
            <CompanyMethods/>
            <CompanyEquivalents/>
            <ContactForm opcoesAssunto={opcoesAssunto} title={t("general-title-contact")}/>
            <Footer/>
        </div>
    )
}
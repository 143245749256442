import React, { useState, useEffect } from 'react';
import './ModalComponent.css'; // Importa o CSS para estilização

function ModalComponent() {
  const [isOpen, setIsOpen] = useState(false);

  // Executa ao carregar a página para abrir o modal
  useEffect(() => {
    setIsOpen(true);
  }, []);

  // Função para fechar o modal
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      {isOpen && (
        <div className="modal-popup">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
              <img src="https://estaticos-ijj.s3.sa-east-1.amazonaws.com/Aviso_Inscricoes_comunidade.png" alt="Descrição da Imagem"  className='desktop-image-popup'/>
            
            
              <img src="https://estaticos-ijj.s3.sa-east-1.amazonaws.com/Aviso_Inscricoes_comunidade.png" alt="Descrição da Imagem" className='mobile-image-popup' />
            
          </div>
          
        </div>
      )}
    </div>
  );
}

export default ModalComponent;

import Card from './components/Cards'
import './Metodology.css'
import { useTranslation } from 'react-i18next';


const Metodology = () => {
    const { t } = useTranslation()
    return (

        <section id='metodologia' className="metodology-main">
            <div className='first-metodology-content'>
                <div>
                    <h2>{t("method-h2-1")}<br></br> {t("method-h2-2")}</h2>
                    <p>
                    {t("method-p")}
                    </p>
                </div>
                <div>
                    <img src='/image/aluno_1.png' alt="Imagem de aluno do Instituto Joga Junto sentado segurando um copo e olhando para o lado"></img>
                </div>


            </div><div className='yellow-container'>

                <div className='yellow-bar'>
                    <p> {t("method-p-2")} </p>
                </div>
            </div>
            <div className='border-yellow'>
                <div className='second-metodology-content'>
                    {/* <UpRightCorner link="/image/corner_3.png"/> */}

                    <ul>
                        <li>


                            <Card
                                frontContent={t("method-title-card-1")}
                                backContent={t("method-text-card-1")}
                                border="#4863F7"
                                imageFront='/image/escola.png'
                                imageDescription="Chapéu - Simbolo da fase de Escola da metologia Joga Junto"
                                backTitle={t("method-title-card-1")}
                                imageBack="/image/escola_little.png"
                            />
                        </li>
                        <li>
                            <Card
                                frontContent={t("method-title-card-2")}
                                backContent={t("method-text-card-2")}
                                border="#04D361"
                                imageFront='/image/imeresized.png'
                                imageDescription="Fábrica - Simbolo da fase de Imersão da metologia Joga Junto"
                                backTitle={t("method-title-card-2")}
                                imageBack="/image/imersao_little.png"
                            />
                        </li>

                        <li>
                        <Card
                            frontContent={t("method-title-card-3")}
                            backContent={t("method-text-card-3")}
                            border="#FD3F3F"
                            imageFront='/image/mentresized.png'
                            imageDescription="Cabeças - Simbolo da fase de Mentoria da metologia Joga Junto"
                            backTitle={t("method-title-card-3")}
                            imageBack="/image/mentoria_little.png"
                        />
                        {/* <div className='sol'>
                        <img src='/image/sol.png' alt='Sol amarelo Simbolo do Instituto Joga Junto'></img>
                    </div> */}
                    </li>
                </ul>

            </div>
        </div>
        </section >
    )
}

export default Metodology
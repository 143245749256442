import './Video.css'
import Typewriter from './Typewriter'
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';




const VideoComponent = () => {
  const { t } = useTranslation()
  const [muted, setMuted] = useState(true);
  const handleUnmute = () => {
    // console.log('unmute')
    if (muted===false) {
      setMuted(true);}
    else {
      setMuted(false)
    }
  };
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setMuted(false);
  //   }, 1000);

  //   return () => clearTimeout(timeout);
  // }, []);

  const phrases = [t("typewriter-1"),  t("typewriter-2"), t("typewriter-3"), t("typewriter-4")];
  return (
    <div className="video-container">
      <video className="video" autoPlay loop muted={muted}>
      <source src="https://estaticos-ijj.s3.sa-east-1.amazonaws.com/video.mp4" type="video/mp4" banner="/image/banner_video.png"/>
      Seu navegador não suporta a tag de vídeo.
      </video>
{/* <video className="video" src="https://estaticos-ijj.s3.sa-east-1.amazonaws.com/video.mp4" autoPlay/> */}
      <div className="overlay" />
      <img className="scroll-button"src='/image/Scroll.png' alt='Elemento para abaixar a tela'></img>
      <div className='banner-text'>
                <h1>{t("bannerh1-1")}<br></br> {t("bannerh1-2")}</h1>
                <span>
                    
                    <Typewriter phrases={phrases} ></Typewriter>
                </span>
            </div>
            {muted ? (
        <div className='muted-button' onClick={handleUnmute}>
          <img className="no-muted-image"src='/image/volume-mute.png' alt='ativar ou desativar audio'></img>
          </div>
      ) : (
        <div className='muted-button' onClick={handleUnmute}>
        <img className="no-muted-image"src='/image/audio.png' alt='ativar ou desativar audio'></img>
      </div>
      )}
      {/* <div className='muted-button' onClick={handleUnmute}>
        <img className="no-muted-image"src='/image/audio.png' alt='ativar ou desativar audio'></img>
      </div> */}
    </div>
  );
};

export default VideoComponent;
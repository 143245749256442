import './Cards.css'

const Card = ({ frontContent, backContent, border, imageFront, imageDescription, backTitle,imageBack, fontColor, titleFontSize, backTitleFontSize, backFontsize, backTextAlign }) => {
  const borderColor = {
    border: `10px solid ${border}`
  };
  
  return (
    <div className="card">
      <div className="card-inner">
        <div className="card-face card-front">
          <div style={borderColor}>
            <div className='card-container'>

            <img src={imageFront} alt={imageDescription}></img>
            <p style={{color:fontColor, fontSize: titleFontSize, maxWidth: '98%'}}>{frontContent}</p>
            </div>
          </div>
        </div>
        <div className="card-face card-back" style={{backgroundColor:border}}>
            <img src={imageBack} alt={imageDescription}></img>
            <h4 style={{ fontSize: backTitleFontSize, padding: '2rem', textAlign:backTextAlign}}>
              {backTitle}
            </h4>
            <p style={{ fontSize: backFontsize }}>
            {backContent}
            </p>
        </div>
      </div>
    </div>
  );
};

export default Card;

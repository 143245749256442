import UpsideLeftCorner from '../Corners/UpsideCorner'
import { PersonCards } from './PersonCards'
import './PersonVoluntary.css'
import { useTranslation } from 'react-i18next';


export const PersonVoluntary = () => {
    const { t } = useTranslation()
    return (
        <section className='voluntary-modalities'>
            <UpsideLeftCorner link='/image/corner_1.png' />


            <h2> {t("person-voluntary-h1")}</h2>
            <div className='voluntary-cards'>


                <PersonCards imageBack="./image/facilitador.png"
                    backgroundColor="#4863F7"
                    imageDescription={t("card-voluntary-title-1")}
                    title={t("card-voluntary-title-1")}
                    content={[t("card-voluntary-text1-1"), t("card-voluntary-text2-1"), t("card-voluntary-text3-1")]} />

                <PersonCards imageBack="./image/mentoria_little.png"
                    backgroundColor="#FD3F3F"
                    imageDescription={t("card-voluntary-title-2")}
                    title={t("card-voluntary-title-2")}
                    content={[t("card-voluntary-text1-2"), t("card-voluntary-text2-2"), t("card-voluntary-text3-2")]}
                    
                    
                    />

                <PersonCards imageBack="./image/outra_modalidade.png"
                    backgroundColor="#FC9700"
                    imageDescription={t("card-voluntary-title-3")}
                    title={t("card-voluntary-title-3")}
                    content={[t("card-voluntary-text1-3"), t("card-voluntary-text2-3")]}
                    />

            </div>
            <div className='voluntary-container'>
                <a href='/VoluntaryPerson#Contato'>
                    <button className='btn-voluntary'>
                    {t("person-voluntary-btn-1")}
                    </button>
                </a>
            </div>
        </section>
    )
}